import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useParams } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const UploadFileRequest = () => {
  const { tokenUpload } = useParams();
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    const token = localStorage.getItem('jwtToken');

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_ENDPOINT}/upload-file/?link_token=${tokenUpload}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadProgress(progress);
        },
      });
      MySwal.fire({
        title: 'Archivo subido correctamente',
        text: response.data.message,
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      MySwal.fire({
        title: 'Error al subir el archivo',
        text: error.response?.data?.detail || 'Error al subir el archivo',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      console.error('Error uploading file:', error);
    } finally {
      setUploadProgress(0);
    }
  };

  return (
    <div className="min-h-screen px-[2%] md:px-[8%] bg-[#f7f7f7] flex justify-center">
      <div className="relative w-full max-w-2xl p-6 bg-excelGreen text-white rounded-lg shadow-md">
        <h2 className="text-xl font-bold mb-4">Subir Archivo</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input type="file" onChange={handleFileChange} className="mt-1 p-2 w-full rounded-md text-black" />
          </div>
          <button type="submit" disabled={!file} className="mt-4 p-2 bg-white text-excelGreen rounded-md hover:bg-green-200 transition-colors">
            Subir Archivo
          </button>
          {uploadProgress > 0 && (
            <div>
              Progreso de subida: {uploadProgress}%
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default UploadFileRequest;
