import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faBroom, faExchangeAlt, faChartBar, faFilePdf, faFileExcel, faClipboard, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import ButtonCustom from './components/ButtonCustom';
import CardHome from './components/CardHome';

function Home() {
  return (
    <div className="px-[2%] mt-8 md:mt-0">
      <div className="h-max md:h-[136px] bg-excelGreen text-white rounded-md">
        <div className="flex flex-col gap-4 items-center h-full p-4">
          <h1 className="text-[24px] font-bold">My Data Worker - Asistente inteligente para procesar archivos PDF y Excel</h1>
          <p className="text-[16px]">Optimiza y gestiona archivos PDF y Excel: conversión, unión, división, limpieza de datos y análisis estadístico en un solo lugar.</p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
        {/*  <a href="/pdfConvert" className=''>
          <div className="h-[172px] flex flex-col justify-center gap-2 bg-[#f7f7f7] px-4 rounded-md hover:bg-green-200 transition-colors ">
            <div className="flex justify-center">
              <FontAwesomeIcon icon={faFileExcel} size="2x" className="text-excelGreen" />
            </div>
            <div className="items-center text-center text-[20px] font-medium">Convertir PDF a Excel</div>
            <p className="text-center text-sm text-gray-600">Convierte tus archivos PDF en tablas de Excel gratis, de manera rápida y precisa.</p>
            <div className="w-full">

            </div>
          </div>
        </a> */}

        <CardHome url="/convertCsvExcel" icon={faExchangeAlt} title="Cambiar entre Excel y CSV" description="Convierte tus archivos entre formatos Excel y CSV de manera sencilla y rápida." />
        <CardHome url="/dataQuality" icon={faChartBar} title="Explora tus datos" description="Analiza tus datos con estadísticas básicas y encuentra patrones o anomalías." />
        <CardHome url="/dataCleaning" icon={faBroom} title="Limpiar Excel" description="Corrige y estandariza automáticamente los formatos y valores en tus datos de Excel." />

        <CardHome url="/pdfMerge" icon={faFilePdf} title="Unir PDF" description="Combina múltiples archivos PDF en uno solo, ordena tus documentos fácilmente." />
        <CardHome url="/pdfSplit" icon={faClipboard} title="Dividir PDF" description="Divide un archivo PDF en varias partes según tus necesidades." />
        <CardHome url="/pdfConvert" icon={faFileExcel} title="Convertir PDF a Excel" description="Convierte tus archivos PDF en tablas de Excel gratis, de manera rápida y precisa." isprotected={true} />
        <CardHome url="/askMyPdf" icon={faFilePdf} title="Pregunta a tu PDF" description="Has preguntas a tus archivos, y que ellos mismos te contesten." isprotected={true} />
        <CardHome url="/shareFile" icon={faShareSquare} title="Compartir Archivos" description="Envia documentos de manera segura" isprotected={true} />
        <CardHome url="/requestDocuments" icon={faShareSquare} title="Solicitar archivos" description="Solicita archivos con un click" isprotected={true} />
        {/* <CardHome url="/#" icon={faFileExcel} title="Traducir un PDF" description="Convierte tus archivos PDF en tablas de Excel gratis, de manera rápida y precisa." isprotected={true} /> */}
        {/* <CardHome url="/#" icon={faFileExcel} title="Consultar a mi PDF" description="Convierte tus archivos PDF en tablas de Excel gratis, de manera rápida y precisa." isprotected={true} /> */}






      </div>

      {/* <div className="flex flex-col gap-4 w-full my-10">
        <h2 className="text-[24px] text-center font-medium">Recientes</h2>
        <div className="h-[60px] flex flex-row justify-between gap-2 bg-[#E2E4E8] px-4 rounded-md items-center">
          <span>File.xlsx</span>
          <ButtonCustom width="w-[65px]">Open</ButtonCustom>
        </div>
        <div className="h-[60px] flex flex-row justify-between gap-2 bg-[#E2E4E8] px-4 rounded-md items-center">
          <span>File.xlsx</span>
          <ButtonCustom width="w-[65px]">Open</ButtonCustom>
        </div>
        <div className="h-[60px] flex flex-row justify-between gap-2 bg-[#E2E4E8] px-4 rounded-md items-center">
          <span>File.xlsx</span>
          <ButtonCustom width="w-[65px]">Open</ButtonCustom>
        </div>
        <div className="h-[60px] flex flex-row justify-between gap-2 bg-[#E2E4E8] px-4 rounded-md items-center">
          <span>File.xlsx</span>
          <ButtonCustom width="w-[65px]">Open</ButtonCustom>
        </div>
        <div className="h-[60px] flex flex-row justify-between gap-2 bg-[#E2E4E8] px-4 rounded-md items-center">
          <span>File.xlsx</span>
          <ButtonCustom width="w-[65px]">Open</ButtonCustom>
        </div>
        <div className="h-[60px] flex flex-row justify-between gap-2 bg-[#E2E4E8] px-4 rounded-md items-center">
          <span>File.xlsx</span>
          <ButtonCustom width="w-[65px]">Open</ButtonCustom>
        </div>
        <div className="h-[60px] flex flex-row justify-between gap-2 bg-[#E2E4E8] px-4 rounded-md items-center">
          <span>File.xlsx</span>
          <ButtonCustom width="w-[65px]">Open</ButtonCustom>
        </div>
      </div> */}
    </div>
  );
}

export default Home;
