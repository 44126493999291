import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

const MySwal = withReactContent(Swal);

function FileUpload({ buttonLabel, redirectPath, title, desc, multipleFile = false, endpoint, nombreArchivo }) {

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [fileUrl, setFileUrl] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const urloffice = useRef(null)

  const onDrop = (acceptedFiles) => {

    const selectedFile = acceptedFiles[0];


    if (selectedFile) {
      if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setFile(selectedFile);
        const url = URL.createObjectURL(selectedFile);
        setFileUrl(url);
        let urlOf = url.replace('blob:', '')

        urloffice.current = urlOf
        setPreview(selectedFile.name);
      } else {
        MySwal.fire({
          title: 'Invalid File Type',
          text: 'Please upload a valid Excel file.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const handleButtonClick = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);


      MySwal.fire({
        title: 'Trabajando Data',
        text: 'Por Favor Espera hasta que la info sea procesada',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.post(`${import.meta.env.VITE_API_ENDPOINT}${endpoint}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          responseType: 'blob',

        });

        MySwal.close();


        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nombreArchivo + file.name); // Nombre del archivo descargado
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        MySwal.fire({
          title: 'File Uploaded',
          text: 'Your file has been uploaded successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          // navigate(redirectPath);
        });
      } catch (error) {
        console.error(error);
        MySwal.close();
        MySwal.fire({
          title: 'Upload Failed',
          text: 'There was an error uploading your file. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }

    } else {
      MySwal.fire({
        title: 'No File Selected',
        text: 'Please select a file to upload.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
    // navigate(redirectPath);
  };

  return (
    <div className="min-h-screen px-[2%] md:px-[8%] bg-[#f7f7f7] flex  justify-center  ">
      <div className="w-full max-w-2xl p-6 bg-excelGreen text-white rounded-lg shadow-md">
        <h2 className="text-xl font-bold mb-4">Limpia tus datos de Excel</h2>
        <p className="text-sm mb-4">
          {desc} Carga tu archivo Excel para corregir formatos y estandarizar valores automáticamente. Mejora la calidad de tus datos de forma rápida.
          <br />

        </p>
        <div
          {...getRootProps()}
          className={`border-2 border-dashed border-gray-500 rounded-lg p-4 mb-4 text-center cursor-pointer ${isDragActive ? 'opacity-50' : ''
            }`}
          onClick={() => fileInputRef.current?.click()}
        >
          <input {...getInputProps()} ref={fileInputRef} />
          <p className="text-white">Arrastra y suelta tu archivo aquí, o haz clic para seleccionar un archivo</p>
        </div>
        {multipleFile && <button
          className="w-full bg-excelDarkGreen text-white py-2 rounded-lg mb-4 hover:bg-excelGreen transition-colors"
          onClick={() => fileInputRef.current?.click()}
        >

          Upload File
        </button>}

        <div className="bg-[#E2E4E8] p-4 mb-4 rounded-lg h-max">
          <h3 className="font-semibold mb-2 text-black">Vista previa</h3>
          <div className="h-[300px] bg-white rounded p-2">
            {fileUrl ? (
              <>
                <p className="text-black">{preview}</p>
                <p className='text-black font-medium'>Tu archivo se ha cargado correctamente. Puedes proceder a Limpiarlo.</p>
              </>
            ) : (
              <p className="text-gray-500">Primeramente carga tu archivo.</p>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <button
            className="bg-[#f7f7f7] text-black px-4 py-2 rounded-lg hover:bg-[#E2E4E8] transition-colors"
            onClick={handleButtonClick}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;