import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function DownloadFile({ endpoint }) {
  const { tokenDownload } = useParams();

  // Ahora puedes usar el token en tu componente


  const handleDownload = async () => {
    
    try {
      const token = localStorage.getItem('jwtToken');
      const headers = {
        accept: 'application/json',

        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${import.meta.env.VITE_API_ENDPOINT}/file-share/${endpoint}${tokenDownload}`, { headers, responseType: 'blob' })
      console.log(response)
      const blob = new Blob([response.data], { type: 'application/zip' });

      // Crear un enlace de descarga para el blob
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'archivos_divididos.zip'); // Nombre del archivo descargado
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error al descargar',
        text: 'Hubo un error al descargar el archivo. Por favor, inténtalo de nuevo.',
      });

    }
  }
  return (
    <div className="min-h-screen px-[2%] md:px-[8%] bg-[#f7f7f7] flex justify-center">
      <div className="relative w-full max-w-2xl p-6 bg-excelGreen text-white rounded-lg shadow-md">
        <h1>Descargar file</h1>

        <button
          className="mt-4 p-2 bg-white text-excelGreen rounded-md hover:bg-green-200 transition-colors"
          onClick={handleDownload}
        >
          Descargar archivo
        </button>
      </div>
    </div>
  );
}

export default DownloadFile;