import { useState, useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import DataAnalysis from './DataAnalysis';
import FileUpload from './FileUpload';
import DataFileUpload from './dataCleaning/FileUpload';
import DataQuality from './dataQuality/FileUpload';
import PdfSplit from './dpfSplit/FileUpload';
import PdfViewer from './pdfMerge/FileUpload';
import PdfConvert from './pdfConvert/FileUpload';
import AskMyPdf from './askMyPdf/askMyPdf'
import ShareFile from './shareFiles/ShareFile';
import Login from './Login';
import './App.css';
import Register from './Register';
import ProtectedRoute from './ProtectedRoute';
import DownloadFile from './sharefilesDowload/DownloadFile';
import RequestFiles from './requestFiles/RequestFiles';
import UploadFileRequest from './upload/UploadFileRequest';
import Header from './components/Header';

function App() {
  const [count, setCount] = useState(0);
  const location = useLocation();
  const [isoppennav, setIsoppennav] = useState(false);

  const navRef = useRef(null);
  const handleClickOutside = (event) => {

    if (navRef.current && !navRef.current.contains(event.target) && !event.target.closest('#btnBurger')) {
      setIsoppennav(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isLoginPage = location.pathname === '/login' || location.pathname === '/register';

  return (
    <div className="flex h-screen">
      {!isLoginPage && <Navbar ref={navRef} isoppennav={isoppennav} />}
      <div className={`flex-1 p-4 overflow-y-auto ${!isLoginPage ? 'xl:ml-80' : ''}`}>
        {!isLoginPage && <Header setIsoppennav={setIsoppennav} isoppennav={isoppennav} />}
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/pdfConvert" element={<ProtectedRoute element={FileUpload} buttonLabel='Convertir PDF a Excel' redirectPath='/pdfConvertSucces' title="Pdf Convert" endpoint='convert-pdf-to-excel/?flavor=stream' nombreArchivo='converted_' />} />

          <Route path="/dataCleaning" element={<DataFileUpload buttonLabel='Limpiar Datos' redirectPath='/limpiarDatosSuccss' title="Data Cleaning" endpoint='clean-file/' nombreArchivo='cleaned_' />} />
          <Route path="/dataQuality" element={<DataQuality buttonLabel='Analizar datos' redirectPath='/dataQualitySuccss' title="Data Quality" endpoint='data-quality/' nombreArchivo='cleaned_' />} />
          <Route path="/pdfMerge" element={<PdfViewer buttonLabel='Unir PDF' redirectPath='/pdfMergeSuccss' title="Unidr Pdf" endpoint='merge-pdfs/' nombreArchivo='pdf_unido_' />} />
          <Route path="/pdfSplit" element={<PdfSplit buttonLabel='Dividir PDF' redirectPath='/pdfSplitSuccss' title="Dividir Pdf" endpoint='split-pdf/' nombreArchivo='pdf_unido_' />} />
          <Route path="/convertCsvExcel" element={<PdfConvert buttonLabel='Convertir' redirectPath='/convertCsvExcelSuccss' title="Convertir Csv <-> Excel'" endpoint='split-pdf/' nombreArchivo='pdf_unido_' />} />
          <Route path="/askMyPdf" element={<AskMyPdf endpoint='ask-my-pdf/' />} />
          <Route path="/shareFile" element={<ShareFile endpoint='share-file/' />} />
          <Route path="/requestDocuments" element={<RequestFiles endpoint='document-requests/' />} />
          <Route path="/upload/:tokenUpload" element={<UploadFileRequest endpoint='document-requests/' />} />
          {/* <Route path="/dataAnalysisUpload" element={<ProtectedRoute element={FileUpload} buttonLabel='Analizar datos' redirectPath='/dataAnalysisanalizar' />} /> */}
          {/* <Route path="/fileu" element={<ProtectedRoute element={FileUpload} buttonLabel='Analizar Dato' redirectPath='/dataAnalysisanalizar' />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dl/:tokenDownload" element={<DownloadFile endpoint='download/' />} />

        </Routes>
      </div>
    </div>
  );
}

export default App;