import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const RequestFiles = () => {
  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [documentTypes, setDocumentTypes] = useState([]);
  const [firstSendDate, setFirstSendDate] = useState('');
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurrencePeriod, setRecurrencePeriod] = useState('monthly'); // Valor por defecto añadido
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    setToken(token);
    if (!token) {
      navigate('/login'); 
      return;
    }
    const fetchDocumentTypes = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_ENDPOINT}/document-types/`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setDocumentTypes(response.data);
      } catch (error) {
        console.error("Error fetching document types:", error);
        // Manejar el error de forma apropiada, por ejemplo, mostrando un mensaje al usuario.
      }
    };
    fetchDocumentTypes();
  }, [token, navigate]); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (!token) {
      MySwal.fire({
        title: 'Error de autenticación',
        text: 'Debes iniciar sesión para realizar esta acción.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_ENDPOINT}/document-requests/`, {
        recipient_name: recipientName,
        recipient_email: recipientEmail,
        document_type: documentType,
        first_send_date: firstSendDate,
        is_recurring: isRecurring,
        recurrence_period: recurrencePeriod,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setSuccess(response.data.message);
      setRecipientName('');
      setRecipientEmail('');
      setDocumentType('');
      setFirstSendDate('');
      setIsRecurring(false);
      setRecurrencePeriod('monthly'); // Restablecer a valor por defecto

    } catch (error) {
      setError(error.response?.data?.detail || 'Error al crear la solicitud');
      MySwal.fire({
        title: 'Error',
        text: error.response?.data?.detail || 'Error al crear la solicitud',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  return (
    <div className="min-h-screen px-[2%] md:px-[8%] bg-[#f7f7f7] flex justify-center">
      <div className="relative w-full max-w-2xl p-6 bg-excelGreen text-white rounded-lg shadow-md">
        <h2 className="text-xl font-bold mb-4">Solicitar Archivos</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        {success && <div className="text-green-500 mb-4">{success}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-white" htmlFor="recipientName">
              Nombre del destinatario:
            </label>
            <input
              type="text"
              id="recipientName"
              className="mt-1 p-2 w-full rounded-md text-black"
              value={recipientName}
              onChange={(e) => setRecipientName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white" htmlFor="recipientEmail">
              Correo electrónico del destinatario:
            </label>
            <input
              type="email"
              id="recipientEmail"
              className="mt-1 p-2 w-full rounded-md text-black"
              value={recipientEmail}
              onChange={(e) => setRecipientEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white" htmlFor="documentType">
              Tipo de documento:
            </label>
            <select
              id="documentType"
              className="mt-1 p-2 w-full rounded-md text-black"
              value={documentType}
              onChange={(e) => setDocumentType(e.target.value)}
              required
            >
              {documentTypes.length > 0 && documentTypes.map(type => (
                <option key={type.id} value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-white" htmlFor="firstSendDate">
              Primera fecha de envío (AAAA-MM-DD):
            </label>
            <input
              type="date"
              id="firstSendDate"
              className="mt-1 p-2 w-full rounded-md text-black"
              value={firstSendDate}
              onChange={(e) => setFirstSendDate(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white">
              ¿Recurrente?
              <input
                type="checkbox"
                checked={isRecurring}
                onChange={(e) => setIsRecurring(e.target.checked)}
              />
            </label>
          </div>
          <div style={{ display: isRecurring ? 'block' : 'none' }}>
              <label className="block text-sm font-medium text-white" htmlFor="recurrencePeriod">
                Periodo de recurrencia:
              </label>
              <select
                id="recurrencePeriod"
                className="mt-1 p-2 w-full rounded-md text-black"
                value={recurrencePeriod}
                onChange={(e) => setRecurrencePeriod(e.target.value)}
              >
                <option value="monthly">Mensual</option>
                <option value="semiannually">Semestral</option>
                <option value="yearly">Anual</option>
              </select>
            </div>
          <button type="submit" className="mt-4 p-2 bg-white text-excelGreen rounded-md hover:bg-green-200 transition-colors">
            Crear Solicitud
          </button>
        </form>
      </div>
    </div>
  );
};

export default RequestFiles;
